.file-upload-wrapper {
	position: relative;
	justify-content: center;
	align-items: center;
}

.upload-image {
	width: 100%;
	height: 100%;
	display: block;
}

.file-upload-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer; /* Makes it clear that it's clickable */
}
